.desktopPanel {
	width: 100%;
	display: none;

	@media (--medium-up) {
		display: initial;
	}
}

.mobilePanel {
	width: 100%;
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	min-height: 100%;
	z-index: 666666668;
}

.mobileOverlay {
	background: var(--wpl-neutral-500);
	position: absolute;
	inset: 0;
}

.mobileBodyOuter {
	height: 100%;
	min-height: 100%;
	width: calc(100% - 48px);
	float: right;
}

.mobileBody {
	height: 100%;
	min-height: 100%;
	width: 100%;
	position: relative;
}

.mobileBodyInner {
	height: 100%;
	min-height: 100%;
	overflow-y: scroll;
	padding-bottom: 100px;
	background: var(--white);
	width: 100%;
	position: relative;
}

.panelBody {
	padding: 20px;
	display: flex;
	flex-direction: column;

	@media (--medium-up) {
		padding: 0;
		float: left;
		width: 100%;
	}
}

.mobileCloseButton {
	-webkit-appearance: none;
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	max-width: 100%;
	text-transform: uppercase;
	position: absolute;
	top: 24px;
	right: 24px;
	padding: 0;

	@media (--medium-up) {
		display: none;
	}
}

.hideWidget {
	display: none;
}