.hero {
	background-color: var(--wpl-neutral-25);
	border-bottom: 1px solid var(--wpl-neutral-100);
	overflow: hidden;
	position: relative;

	&.dark {
		background-color: var(--token-dark-surface-faint, #15181e);

		& h1 {
			color: var(--token-dark-foreground-primary, #d5d7db);
		}
	}
}

.inner {
	composes: g-grid-container from global;
	composes: g-grid from global;
	padding-bottom: var(--wpl-spacing-06);
	padding-top: var(--padding-top);

	--padding-top: var(--wpl-spacing-08);

	@media (--medium) {
		padding-bottom: var(--wpl-spacing-08);

		--padding-top: var(--wpl-spacing-13);
	}

	@media (--large) {
		padding-bottom: var(--wpl-spacing-10);

		--padding-top: var(--wpl-spacing-14);
	}

	&.lessPadding {
		--padding-top: var(--wpl-spacing-05);

		@media (--medium) {
			--padding-top: var(--wpl-spacing-06);
		}

		@media (--large) {
			--padding-top: var(--wpl-spacing-08);
		}
	}
}

.content-small {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: 1 / 7;
	}

	@media (--large) {
		grid-column: 1 / 5;
	}
}

.content-medium {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: 1 / 10;
	}

	@media (--large) {
		grid-column: 1 / 7;
	}
}

.heading {
	composes: g-type-display-2 from global;
	color: var(--wpl-neutral-900);
	margin: 0;
	padding-bottom: var(--wpl-spacing-05);
}

.description {
	composes: g-type-body from global;
	color: var(--wpl-neutral-600);
	& p {
		margin: 0;
	}
}

.searchBox {
	padding-top: var(--wpl-spacing-05);

	@media (--large) {
		padding-top: var(--wpl-spacing-07);
	}
}

.toggle {
	padding-top: var(--wpl-spacing-02);
}

.cta {
	padding-top: var(--wpl-spacing-06);
}

.pattern {
	display: none;
	max-width: 50%;
	position: absolute;
	right: 0;
	top: 0;

	@media (--medium-up) {
		display: initial;
	}
}
