.body {
	composes: g-grid-container from global;
	composes: g-grid from global;
	padding-top: 24px;
	padding-bottom: 24px;

	@media (--medium-up) {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	@media (--large) {
		padding-top: 88px;
		padding-bottom: 88px;
	}
}

.mobileHeader {
	grid-column: 1 / -1;
	padding-bottom: 24px;

	@media (--medium-up) {
		display: none;
	}
}

.panel {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: 1 / 5;
	}

	@media (--large) {
		grid-column: 1 / 4;
	}
}

.results {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: 5 / -1;
	}

	@media (--large) {
		grid-column: 4 / -1;
	}
}
