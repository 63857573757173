.mobileHeader {
	width: 100%;
}

.heading {
	display: flex;
	justify-content: space-between;
}

.headingText {
	composes: g-type-body-x-strong from global;
	margin: 0;
}

.filterTags {
	display: flex;
	gap: 8px;
	padding-top: 8px;
	width: 100%;
	overflow-x: auto;
}
