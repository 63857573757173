.filterTag {
	padding: 10px 16px;
	background: var(--wpl-blue-50);
	border-radius: 4px;
	display: flex;
	gap: 12px;
	appearance: none;
	border: none;
	cursor: pointer;
	align-items: center;
	width: fit-content;
}

.filterTagLabel {
	composes: g-type-body from global;
	color: var(--wpl-blue-600);
	white-space: nowrap;
}
