.text {
	margin: 0;

	& a {
		color: var(--wpl-blue-500);
		text-decoration: underline;

		&:hover {
			color: var(--wpl-blue-600);
		}
	}
}

.color {
	color: var(--text-color, --black);
}

.fontWeight {
	font-weight: var(--font-weight);
}

.marginTopSm {
	margin-top: var(--margin-top-sm, 0);
}

.marginTopMd {
	@media (--medium-up) {
		margin-top: var(--margin-top-md, 0);
	}
}

.marginTopLg {
	@media (--large) {
		margin-top: var(--margin-top-lg, 0);
	}
}

.marginRightSm {
	margin-right: var(--margin-right-sm, 0);
}

.marginRightMd {
	@media (--medium-up) {
		margin-right: var(--margin-right-md, 0);
	}
}

.marginRightLg {
	@media (--large) {
		margin-right: var(--margin-right-lg, 0);
	}
}

.marginBottomSm {
	margin-bottom: var(--margin-bottom-sm, 0);
}

.marginBottomMd {
	@media (--medium-up) {
		margin-bottom: var(--margin-bottom-md, 0);
	}
}

.marginBottomLg {
	@media (--large) {
		margin-bottom: var(--margin-bottom-lg, 0);
	}
}

.marginLeftSm {
	margin-left: var(--margin-left-sm, 0);
}

.marginLeftMd {
	@media (--medium-up) {
		margin-left: var(--margin-left-md, 0);
	}
}

.marginLeftLg {
	@media (--large) {
		margin-left: var(--margin-left-lg, 0);
	}
}

.marginYSm {
	margin-top: var(--margin-y-sm, 0);
	margin-bottom: var(--margin-y-sm, 0);
}

.marginYMd {
	@media (--medium-up) {
		margin-top: var(--margin-y-md, 0);
		margin-bottom: var(--margin-y-md, 0);
	}
}

.marginYLg {
	@media (--large) {
		margin-top: var(--margin-y-lg, 0);
		margin-bottom: var(--margin-y-lg, 0);
	}
}

.marginXSm {
	margin-right: var(--margin-x-sm, 0);
	margin-left: var(--margin-x-sm, 0);
}

.marginXMd {
	@media (--medium-up) {
		margin-right: var(--margin-x-md, 0);
		margin-left: var(--margin-x-md, 0);
	}
}

.marginXLg {
	@media (--large) {
		margin-right: var(--margin-x-lg, 0);
		margin-left: var(--margin-x-lg, 0);
	}
}
